<template>
  <div
    :class="['home', hotelNameUpperCase('HOUIT') ? 'HOUIT-home' : '']"
    ref="home"
  >
    <HOUITNavBar
      v-if="hotelNameUpperCase('HOUIT')"
      :orderLength="order.length"
      @goMyOrder="goMyOrder"
    />
    <van-nav-bar :left-arrow="false" v-else>
      <template #title>
        <div style="display: flex; align-items: center">
          <img
            :src="brandUrl"
            alt=""
            :style="{
              height: '24px',
              width: 'auto',
              backgroundColor: hotelNameUpperCase('atlht') ? '' : '#FFF',
            }"
          />
          <div class="hotelname word-break" style="white-space: pre-wrap">
            {{ hotelName }}
          </div>
        </div>
      </template>

      <template #right>
        <van-badge v-if="order.length > 0" :content="order.length">
          <img
            :src="
              hotelNameUpperCase('HOUIT')
                ? require('@/assets/img/order-blue.png')
                : require('@/assets/img/order.png')
            "
            @click="goMyOrder"
            alt=""
            style="height: 19.5pt"
          />
        </van-badge>
        <img
          v-else
          :src="
            hotelNameUpperCase('HOUIT')
              ? require('@/assets/img/order-blue.png')
              : require('@/assets/img/order.png')
          "
          @click="goMyOrder"
          alt=""
          style="height: 19.5pt"
        />
      </template>
    </van-nav-bar>

    <div class="section-area">
      <van-icon
        class="arrow-left"
        v-if="mealPeriodList.length > 3"
        size="15pt"
        name="arrow-left"
      />
      <div ref="section" class="section">
        <div
          :id="mealPeriod.id"
          :ref="mealPeriod.id"
          class="item"
          v-for="(mealPeriod, index) in mealPeriodList"
          :key="index"
          @click="chooseType(mealPeriod)"
        >
          <div
            v-if="!loading"
            class="top-text word-break"
            style="background-color: lightgrey"
            :class="{ activeType: active === mealPeriod.id }"
          >
            {{ mealPeriod.mealPeriod }}
          </div>
        </div>
      </div>
      <van-icon
        class="arrow-right"
        v-if="mealPeriodList.length > 3"
        size="15pt"
        name="arrow"
      />
    </div>
    <div
      v-if="tips"
      style="
        width: 100%;
        border-top: 2pt solid #dfdfdf;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2pt 13pt;
      "
    >
      <span
        class="word-break"
        style="
          font-size: 10pt;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          line-height: 12pt;
          color: #636464;
        "
        ><img
          v-show="timeOpen"
          style="height: 12pt; margin-right: 5pt; margin-bottom: 1pt"
          src="@/assets/img/时间.png"
        />
        <img
          v-show="!timeOpen"
          style="height: 12pt; margin-right: 5pt; margin-bottom: 1pt"
          src="@/assets/img/时间2.png"
        />
        <span v-if="tips.includes('Advance order available.')"
          ><span>{{ tips.substring(0, 24) }}</span
          ><br /><span>{{ tips.substring(24, tips.length) }}</span></span
        >
        <span v-else>{{ tips }}</span>
      </span>
    </div>

    <div class="content" ref="categoryContent">
      <div
        style="
          font-size: 10pt;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #636464;
          display: flex;
          flex-flow: column;
          align-items: center;
          justify-content: center;
        "
        ref="tips"
        v-if="!loading"
      >
        <span class="word-break" v-show="mealPeriodList.length == 0"
          >We are not accepting immediate orders right now.</span
        >
        <span
          class="word-break"
          v-show="
            nearEndTipTime != '' &&
            addFoodBtn === false &&
            !nowMealPeriodAdvanced
          "
          style="text-indent: 25px"
          >Ordering ended at {{ nearEndTipTime }}.</span
        >
      </div>
      <van-collapse
        v-model="activeName"
        accordion
        @change="activeNameChange"
        ref="vanCollapse"
      >
        <van-collapse-item
          v-for="(item, index) in categoryList"
          :key="index"
          :title="item.categoryName"
          :label="item.description"
          :name="item.id"
          class="food-collapse"
          :id="'collapse' + item.id"
          :ref="'collapse' + item.id"
        >
          <div v-if="enableUpc && opt === 'staff'">
            <MainStaffEnableUpc
              v-for="(food, mainIndex) in item.mainDishList"
              :item="item"
              :food="food"
              :key="mainIndex"
              :index="mainIndex"
              :cateIndex="index"
              :enableUpc="enableUpc"
              :opt="opt"
              :addFoodBtn="addFoodBtn"
              @showScanStaff="(params) => showScanStaff(params)"
              @uploadImage="staffUploadFoodImage"
            />
          </div>
          <div v-else>
            <MainItem
              v-for="(food, index) in item.mainDishList"
              :item="item"
              :food="food"
              :key="index"
              :index="index"
              :addFoodBtn="addFoodBtn"
              :id="'collapseMainItem' + food.id"
              @showFoodInfo="
                (food) => {
                  showFoodInfo(food, item.id);
                }
              "
              @showIconInfo="showIconInfo"
            />
          </div>

          <!-- 自定义右侧按钮 -->
          <div
            slot="icon"
            class="food-collapse-right-slot"
            v-if="
              shoppingFood &&
              shoppingFood.length &&
              shoppingFood.findIndex(
                (shoppingFoodItem) => shoppingFoodItem.categoryId == item.id
              ) > -1
            "
          ></div>
        </van-collapse-item>
      </van-collapse>
    </div>

    <!-- 非扫码商品 -->
    <div class="bottom" v-if="!enableUpc">
      <div style="display: flex; align-items: center">
        <van-badge
          v-if="allNum > 0"
          :content="allNum"
          style="margin-right: 10pt; top: 0pt"
          class="shoppingCart"
        >
          <img src="../assets/img/购物车.png" alt="" @click="placeOrder" />
        </van-badge>
        <img
          v-else
          src="../assets/img/购物车.png"
          style="margin-right: 10pt"
          alt=""
          @click="placeOrder"
        />
        <span v-if="showPrice" class="price">${{ allPrice.toFixed(2) }}</span>
      </div>

      <div class="btn" @click="placeOrder">
        <span>MY {{ isParking() ? "TICKET" : "ORDER" }}</span>
      </div>
    </div>

    <!-- 扫码商品 -->
    <div v-else style="width: 100%; display: flex; justify-content: center">
      <div class="footer" v-if="opt !== 'staff'">
        <div class="footer-item scanBtn" @click="scanCode">
          <svg-icon iconClass="scan" className="footer-scan"></svg-icon>
          <span>Scan&nbsp;Barcode</span>
        </div>
        <div class="footer-item" @click="placeOrder">
          <van-badge v-if="allNum > 0" :content="allNum">
            <svg-icon
              iconClass="gwc"
              style="width: 18pt; height: 18pt; font-size: 0"
            ></svg-icon>
          </van-badge>
          <svg-icon v-else iconClass="gwc" style="width: 18pt"></svg-icon>
          <span style="margin-left: 10px">${{ allPrice.toFixed(2) }}</span>
        </div>
      </div>

      <div
        class="footer-staff"
        @click="showScanStaff({ type: 'add' })"
        v-if="opt === 'staff'"
      >
        Scan Barcode
      </div>
    </div>

    <van-popup
      class="foodModel"
      v-model="foodInfoShow"
      round
      position="bottom"
      :style="{ height: '75%' }"
    >
      <div style="height: 100%; background-color: #ffffff">
        <div class="title">
          <span class="word-break">{{ selectFood.name }}</span>
          <van-icon @click="close" class="icon" size="15pt" name="cross" />
        </div>
        <div
          style="
            height: calc(100% - 130pt);
            overflow-y: auto;
            background-color: #ffffff;
          "
        >
          <div class="item">
            <div class="item-name">
              <span>Quantity</span>
            </div>
            <van-stepper v-model="selectFood.num" min="0" integer />
          </div>
          <!-- <div
            class="item"
            v-if="selectFood.halfPrice != null && selectFood.halfPrice != 0"
          >
            <div class="item-name">
              <span class="word-break"
                >{{ selectFood.halfName }} Or {{ selectFood.fullName }}</span
              >
            </div>
            <van-radio-group class="radio-group" v-model="selectFood.isFull">
              <van-radio :name="false" icon-size="12pt">
                {{ selectFood.halfName }} &nbsp;&nbsp;
                <span>${{ selectFood.halfPrice }}</span>
              </van-radio>
              <van-radio :name="true" icon-size="12pt">
                {{ selectFood.fullName }} &nbsp;&nbsp;
                <span>${{ selectFood.fullPrice }}</span>
              </van-radio>
            </van-radio-group>
          </div> -->
          <div
            class="item"
            v-for="(item, index) in selectFood.foods"
            :key="index"
          >
            <div class="item-name">
              <span class="word-break"
                >{{ item.title
                }}{{
                  item.maxNum == 1
                    ? " - Choose 1"
                    : " - Choose up to " + item.maxNum
                }}</span
              >
            </div>

            <!-- <van-radio-group
              v-if="item.type == 'Radio'"
              class="radio-group"
              v-model="item.checked"
            >
              <van-radio
                v-for="food in item.optionList"
                :key="food.id"
                :name="food.id"
                icon-size="12pt"
              >
                {{ food.name }} &nbsp;&nbsp;
                <span v-if="food.price && food.price != 0"
                  >+&nbsp;${{ food.price }}</span
                >
              </van-radio>
            </van-radio-group> -->

            <van-checkbox-group
              class="checked-group"
              v-model="item.checked"
              @change="checkedChange(item)"
            >
              <van-checkbox
                v-for="food in item.optionList"
                :key="food.id"
                :shape="
                  item.type == 'Radio' && item.maxNum == 1 ? 'round' : 'square'
                "
                icon-size="12pt"
                :name="food.id"
                @click="checkbox(item, food.id)"
                >{{ food.name }} &nbsp;&nbsp;
                <span v-if="food.price && food.price != 0 && showPrice"
                  >+&nbsp;${{ food.price }}</span
                ></van-checkbox
              >
            </van-checkbox-group>
          </div>
          <div
            class="item"
            v-if="selectFood.drinks && selectFood.drinks.length > 0"
          >
            <div class="item-name">
              <span>Beverage</span>
            </div>
            <div class="select">
              <div
                v-for="(item, index) in selectFood.drinks"
                :key="index"
                :class="item.checked ? 'type-checked' : 'type'"
                @click="choseDrink(item.id)"
              >
                <span>{{ item.title }}</span>
              </div>
            </div>

            <!--            <van-checkbox-group-->
            <!--              class="checked-group"-->
            <!--              v-model="selectFood.drinksChildrenChecked"-->
            <!--              :min="1"-->
            <!--              :max="2"-->
            <!--            >-->
            <!--              <van-checkbox-->
            <!--                v-for="item in selectFood.drinksChildren"-->
            <!--                :key="item.id"-->
            <!--                :name="item.id"-->
            <!--                shape="square"-->
            <!--                icon-size="12pt"-->
            <!--                @click="radioChange('beverage')"-->
            <!--              >-->
            <!--                {{ item.name }}&nbsp;&nbsp;<span-->
            <!--                v-if="item.price && item.price != 0"-->
            <!--              >+&nbsp;${{ item.price }}</span-->
            <!--              >-->
            <!--              </van-checkbox>-->
            <!--            </van-checkbox-group>-->

            <van-radio-group
              class="radio-group"
              v-model="selectFood.drinksChildrenChecked"
            >
              <van-radio
                v-for="item in selectFood.drinksChildren"
                :key="item.id"
                :name="item.id"
                icon-size="12pt"
              >
                {{ item.name }}&nbsp;&nbsp;<span
                  v-if="item.price && item.price != 0 && showPrice"
                  >+&nbsp;${{ item.price }}</span
                >
              </van-radio>
            </van-radio-group>
          </div>
          <div
            class="remark"
            v-if="posConfig.posBridge == 'HVT' || selectFood.messageModifierId"
          >
            <div class="item-name" style="font-size: 12pt">
              <span class="word-break">Special Instructions</span>
              <br />
              <span
                class="word-break"
                style="font-size: 10pt; font-weight: normal"
                >(Please indicate any allergies or dietary restrictions)</span
              >
            </div>
            <van-field
              maxlength="60"
              class="input word-break"
              v-model="selectFood.remark"
              type="input"
            />
          </div>
        </div>

        <div style="padding: 10pt" v-if="this.addFoodBtn">
          <van-button
            class="btn"
            style="color: #fff"
            size="large"
            @click="addShoppingFood(false)"
            >ADD
          </van-button>
        </div>
      </div>
    </van-popup>
    <van-popup class="iconModel" v-model="showIcon" round>
      <div class="title">
        <span class="word-break">Good To Know</span>
        <van-icon
          @click="showIcon = false"
          class="icon"
          size="15pt"
          name="cross"
        />
      </div>
      <div style="text-align: left; padding: 10pt">
        <div style="display: flex; align-items: center; margin-bottom: 10pt">
          <img class="icon" src="../assets/img/1.png" />
          <span style="font-size: 13pt"
            >&nbsp;&nbsp;&nbsp;GF (Gluten Free)</span
          >
        </div>
        <div style="display: flex; align-items: center; margin-bottom: 10pt">
          <img class="icon" src="../assets/img/2.png" />
          <span style="font-size: 13pt">&nbsp;&nbsp;&nbsp;DF (Dairy Free)</span>
        </div>
        <div style="display: flex; align-items: center; margin-bottom: 10pt">
          <img class="icon" src="../assets/img/3.png" />
          <span style="font-size: 13pt">&nbsp;&nbsp;&nbsp;VG (Vegan)</span>
        </div>
        <div style="display: flex; align-items: center; margin-bottom: 10pt">
          <img class="icon" src="../assets/img/4.png" />
          <span style="font-size: 13pt">&nbsp;&nbsp;&nbsp;V (Vegetarian)</span>
        </div>
        <div style="display: flex; align-items: center; margin-bottom: 10pt">
          <img class="icon" src="../assets/img/5.png" />
          <span style="font-size: 13pt">&nbsp;&nbsp;&nbsp;NF (Nuts Free)</span>
        </div>
        <div style="display: flex; align-items: center; margin-bottom: 10pt">
          <img class="icon" src="../assets/img/6.png" />
          <span style="font-size: 13pt"
            >&nbsp;&nbsp;&nbsp;N (Contain Nuts)</span
          >
        </div>
      </div>
    </van-popup>
    <van-popup class="upsellModel" v-model="upsellShow" round>
      <div
        class="title word-break"
        style="font-weight: normal; text-align: left"
      >
        Would you like to add something extra?
      </div>
      <div class="content">
        <div
          class="item word-break"
          v-for="category in upsellCategorys"
          :key="category.id"
          style="display: flex; align-items: center"
          @click="showUpsell(category.id)"
        >
          <!-- <img
            src="@/assets/img/右箭头.png"
            style="height: 16pt; width: 16pt"
          /> -->
          <van-icon name="play-circle" size="15pt" style="margin-right: 5pt" />
          <span style="font-size: 13pt">
            {{ category.categoryName }}
          </span>
        </div>
      </div>
      <div class="btn-group">
        <van-button
          class="btn-skip"
          style="height: 30px"
          plain
          @click="skipUpsell"
          >SKIP</van-button
        >
      </div>
    </van-popup>
    <!-- 顾客扫码 -->
    <ScanCode
      ref="scan"
      v-if="visibleScan"
      @closed="visibleScan = false"
      @confirmFood="ScanCodeConfirmFood"
    />

    <!-- 员工扫码 -->
    <ScanCodeStaff
      ref="scanStaff"
      v-if="visibleScanStaff"
      @closed="visibleScanStaff = false"
      @successCallback="ScanCodeStaffCallback"
    />

    <van-uploader
      ref="vanUploader"
      style="display: none"
      :after-read="uploadImageFoodafterRead"
    />

    <!-- 购买数量超限提示 -->
    <MaxMenuTip ref="maxMenuTipRef" @MaximumCallback="placeOrder" />

    <!-- 停车费计算时间弹框 -->
    <ParkingPopup
      ref="parkingPopupRef"
      v-if="parkingPopupVisible"
      @callback="parkingCallback"
    />
  </div>
</template>

<script>
import { getAction, deleteAction, putAction, postAction } from "@/api/manage";
import { mapGetters, mapActions } from "vuex";
import { randomUUID, getPramas } from "@/utils/utils";
import { Toast } from "vant";
import lodash from "lodash";
import request from "@/utils/request";
import { Dialog } from "vant";
import moment from "moment-timezone";
import ScanCode from "@/components/scanCode.vue";
import ScanCodeStaff from "@/components/scanCodeStaff.vue";
import MainItem from "@/components/mainItem/main-item.vue";
import MainStaffEnableUpc from "@/components/mainItem/main-staff-enableUpc.vue";
import MaxMenuTip from "@/components/maxMenuTip.vue";
import ParkingPopup from "@/components/ParkingPopup/index.vue";
import HOUITNavBar from "@/components/HotelComponents/HOUIT-NavBar.vue";

export default {
  name: "Home",
  components: {
    ScanCode,
    ScanCodeStaff,
    MainItem,
    MainStaffEnableUpc,
    MaxMenuTip,
    ParkingPopup,
    HOUITNavBar,
  },
  data() {
    return {
      foodInfoShow: false,
      url: {
        list: "/adminApi/foodOrder/list",
        login: "/api/common/user/hotel_dept_name/login",
        getUserInfo: "/api/common/user/current_user",
        hotelQuery: "/api/sys_admin/hotel/query",
        getDisclaimerByHotelId: "/adminApi/foodOrder/getDisclaimerByHotelId",
        getOperationsByMealPeriodId:
          "/adminApi/foodOrder/getOperationsByMealPeriodId",
        getHotelInfo: "/adminApi/hotelinfo/infobyhotel",
        getPOSConfig: "/adminApi/omnivore/config",
        getTipsByMealPeriodId: "/adminApi/foodOrder/getTipsByMealPeriodId",
      },
      mealPeriodList: [],
      activeName: "",
      selectFood: {}, //弹窗的餐品信息
      active: "",
      activeMealPeriod: "",
      week: ["Sun", "Mon", "Tues", "Wed", "Thurs", "Fri", "Sat"],
      categoryList: [],
      hotelDeptName: "roomserviceqr",
      hotelName: "Hotel2",
      password: "654321",
      tips: "",
      // showDisclaims: false,
      timeOpen: false,
      showIcon: false,
      upsellShow: false,
      brandUrl: "",
      upsellCategorysId: [],
      disclaimerList: [],
      upsellCategorys: [],
      mealPeriodAdvancedArr: [],
      curMobilePrompt: "",
      addFoodBtn: true,
      endTimeTody: "",
      isAllowAdvanced: true,
      nearEndTipTime: "",
      posConfig: {},
      nowMealPeriodAdvanced: false,
      roomNum: "",
      locationType: "Guest Room",
      loading: true,
      visibleScan: false,
      visibleScanStaff: false,
      opt: "",
      enableUpc: false, // 是否是扫码商品
      scanCategoryList: [],
      uploadImageFood: {},
      showPrice: true,
      maxMenuItem: 0, // 最大下单商品数量  0 表示不限制
      parkingPopupVisible: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    let toQuery = to.query;
    let hotelName = localStorage.getItem("hotelName");

    if (!hotelName || !toQuery.hotelname) {
      // 没值的时候不做清理订单处理 适配三方支付失败返回
      next();
      return;
    }
    if (hotelName !== toQuery.hotelname) {
      next((vm) => {
        vm.clearFood();
        vm.clearOrder();
      });
    } else {
      next();
    }
  },
  watch: {
    $route(to, from) {
      window.location.reload();
    },
  },
  computed: {
    ...mapGetters({
      shoppingFood: "foods", //购物车中的餐品
      allPrice: "allPrice",
      allNum: "allNum",
      order: "order",
      primaryColor: "primaryColor",
    }),
  },
  mounted() {
    /**
     * @description 三方支付失败/取消跳转 不携带参数  https://xxxxxx.xxxx/#/
     * @description 三方支付成功跳转 携带参数 #异常  https://xxxxxx.xxxx/?ssl_result_message=APPROVAL#/   为适配已生成二维码链接原因 无法使用history模式
     * @description 先判断有没有
     * */
    let params = getPramas(window.location.href);
    let paymentStorageDara =
      window.sessionStorage.getItem("paymentStorageDara");
    console.log("getPramas=============", params);

    const { room, location, opt, user, hotelname } = this.$route.query;
    if ((room || location || opt || user, hotelname)) {
      // 正常访问进入 不做跳转处理
      sessionStorage.setItem("queryStr", window.location.href.split("?")[1]);
      window.sessionStorage.removeItem("paymentStorageDara");
    } else {
      if (!paymentStorageDara && params.ssl_result_message) {
        // 三方支付成功 防止多次生成订单
        window.location.replace(
          `https://${window.location.host}/#/?${sessionStorage.getItem(
            "queryStr"
          )}`
        );
        return;
      }
      // 支付跳转返回  有没有ssl_result_message是判断支付成功或失败的条件
      if (paymentStorageDara && !params.ssl_result_message) {
        // // 三方支付返回跳转到首页 支付失败
        window.location.href = `https://${window.location.host}/#/confirmOrder`;
        return;
      } else if (
        paymentStorageDara &&
        params.ssl_result_message === "APPROVAL"
      ) {
        // 三方支付返回跳转到首页 成功
        window.location.href = `https://${window.location.host}/#/confirmOrder?${params.fullPath}`;
        return;
      }
    }
  },
  created() {
    if (this.$route.query.room) {
      this.roomNum = this.$route.query.room;
    }
    if (this.$route.query.location) {
      this.locationType = this.$route.query.location;
    }
    if (this.$route.query.opt) {
      this.opt = this.$route.query.opt;
    }
    if (this.$route.query.user) {
      localStorage.setItem("staffUserName", this.$route.query.user);
    } else {
      localStorage.setItem("staffUserName", "");
    }
    if (this.$route.query.charge) {
      sessionStorage.setItem("chargeType", this.$route.query.charge);
    }

    localStorage.setItem("locationType", this.locationType);
    if (this.$route.query.hotelname) {
      this.hotelName = this.$route.query.hotelname;
      this.login();
    } else {
      if (localStorage.getItem("userInfo")) {
        // this.getHotelInfo()
        this.getUserInfo();
        this.hotelQuery();
      }
    }
    if (this.hotelName == "pmont" || this.hotelName == "PMONT") {
      this.showPrice = false;
    }
  },
  methods: {
    moment,
    ...mapActions([
      "addFood",
      "clearFood",
      "clearOrder",
      "removeFood",
      "updateMealPeriodOpenTime",
      "editColor",
      "resetState",
    ]),
    scanCode() {
      if (
        this.maxMenuItem !== 0 &&
        this.shoppingFood.length >= this.maxMenuItem
      ) {
        this.$refs.maxMenuTipRef.init(this.maxMenuItem);
        return;
      }
      let mealPeriod = this.mealPeriodList.find(
        (item) => item.id == this.active
      );
      // let categoryList = mealPeriod.categoryList.map((item) => {
      //   return {
      //     text: item.categoryName,
      //     id: item.id,
      //     children: item.mainDishList.map((mainDishItem) => {
      //       return {
      //         text: mainDishItem.name,
      //         id: mainDishItem.id,
      //         upc: mainDishItem.upc,
      //         image: mainDishItem.image,
      //         fullPrice: mainDishItem.fullPrice,
      //         description: mainDishItem.description,
      //       };
      //     }),
      //   };
      // });
      this.visibleScan = true;
      this.$nextTick(() => {
        this.$refs.scan.init(mealPeriod.categoryList);
      });
    },
    showScanStaff(params) {
      console.log(
        "categoryId===============",
        params.categoryId,
        params.foodId
      );
      let mealPeriod = this.mealPeriodList.find(
        (item) => item.id == this.active
      );
      let categoryList = mealPeriod.categoryList.map((item) => {
        return {
          text: item.categoryName,
          id: item.id,
          children: item.mainDishList.map((mainDishItem) => {
            return {
              text: mainDishItem.name,
              id: mainDishItem.id,
              upc: mainDishItem.upc,
              image: mainDishItem.image,
            };
          }),
        };
      });
      params.categoryList = categoryList;
      this.visibleScanStaff = true;
      this.$nextTick(() => {
        this.$refs.scanStaff.init(params);
      });
    },
    ScanCodeStaffCallback({
      activeIndex,
      mainIndex,
      upcUpdateTime,
      upc,
      image,
    }) {
      console.log(
        "ScanCodeStaffCallback============",
        activeIndex,
        mainIndex,
        upcUpdateTime,
        upc,
        image
      );
      if (upcUpdateTime) {
        this.$set(
          this.categoryList[activeIndex].mainDishList[mainIndex],
          "upcUpdateTime",
          upcUpdateTime
        );
      }
      if (upc) {
        this.$set(
          this.categoryList[activeIndex].mainDishList[mainIndex],
          "upc",
          upc
        );
      }
      if (image) {
        this.$set(
          this.categoryList[activeIndex].mainDishList[mainIndex],
          "image",
          image
        );
      }
    },
    staffUploadFoodImage(foodInfo) {
      this.uploadImageFood = foodInfo;
      this.$refs.vanUploader.chooseFile();
    },
    uploadImageFoodafterRead(file) {
      const formData = new FormData();
      formData.append("file", file.file);
      request
        .post("/adminApi/file/upload", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          if (res.status === "0") {
            request
              .post("/adminApi/foodOrder/saveMainDishUpc", {
                id: this.uploadImageFood.foodId,
                hotelId: localStorage.getItem("hotelId"),
                image: res.data,
              })
              .then((saveRes) => {
                if (saveRes.status === "0") {
                  // 图片回显
                  const { cateIndex, mainIndex } = this.uploadImageFood;
                  this.$set(
                    this.categoryList[cateIndex].mainDishList[mainIndex],
                    "image",
                    res.data
                  );
                  Toast("SUCCESS");
                } else {
                  Toast("Error" + saveRes.msg);
                }
              });
          } else {
            Toast("Error" + res.msg);
          }
        })
        .catch((err) => {
          Toast("Error" + JSON.stringify(err));
        });
    },
    activeNameChange(activeName) {
      if (activeName) {
        setTimeout(() => {
          const targetElement = document.getElementById(
            "collapse" + activeName
          );
          targetElement.scrollIntoView({ behavior: "smooth" });
        }, 400);
      }
    },

    login() {
      postAction(this.url.login, {
        hotelDeptName: this.hotelDeptName,
        hotelName: this.hotelName,
        password: this.password,
      })
        .then((res) => {
          if (res.msg == "Login success") {
            localStorage.setItem("hotelName", this.hotelName);
            localStorage.setItem("HTTOKEN", res.data.token);
            localStorage.setItem(
              "loginInfo",
              JSON.stringify({
                hotelDeptName: this.hotelDeptName,
                hotelName: this.hotelName,
                password: this.password,
              })
            );
            this.getUserInfo();
            this.hotelQuery();
            // this.resetState();
          } else {
            Toast({
              message: `Invalid RoomServiceQR access`,
              duration: 3000,
              className: "toast",
              getContainer: () => {
                return this.$refs.home;
              },
            });
          }
        })
        .catch((err) => {
          Toast({
            message: `Invalid RoomServiceQR access`,
            duration: 3000,
            className: "toast",
            getContainer: () => {
              return this.$refs.home;
            },
          });
        });
    },
    getDisclaimer() {
      this.disclaimer = "";
      getAction(this.url.getDisclaimerByHotelId, {
        hotelId: localStorage.getItem("hotelId"),
      }).then((res) => {
        this.disclaimer = res.data.disclaimer;
      });
    },
    getUserInfo() {
      getAction(this.url.getUserInfo).then((res) => {
        console.log("res", res);
        localStorage.setItem("hotelId", res.data.hotelId);
        localStorage.setItem("username", res.data.username);
        localStorage.setItem("hotelDeptId", res.data.hotelDeptId);
        localStorage.setItem("hotelDeptName", res.data.hotelDeptName);
        localStorage.setItem("userInfo", JSON.stringify(res.data));
        this.initData();
        // this.getDisclaimer()
        this.getHotelInfo();
        this.getPosConfig();
      });
    },
    getPosConfig() {
      getAction(
        `${this.url.getPOSConfig}?hotelId=${localStorage.getItem("hotelId")}`
      ).then((res) => {
        localStorage.setItem("posConfig", JSON.stringify(res.data));
        this.posConfig = JSON.parse(localStorage.getItem("posConfig")) || {
          posBridge: "HVT",
        };
      });
    },
    hotelQuery() {
      postAction(this.url.hotelQuery, {
        hotelNo: localStorage.getItem("hotelName"),
      }).then((res) => {
        this.hotelName = res.data.name;
        localStorage.setItem("timeZone", res.data.timeZone);
        localStorage.setItem("activeTime", res.data.activeTime);
      });
    },
    hotelNameUpperCase(value) {
      if (!value) return false;
      if (
        localStorage.getItem("hotelName") &&
        localStorage.getItem("hotelName").toUpperCase() === value.toUpperCase()
      ) {
        return true;
      }
      return false;
    },
    seeToast(value) {
      Toast(value);
    },
    goMyOrder() {
      if (this.order.length == 0) {
        return;
      }
      let query = {};
      if (this.roomNum) {
        query.room = this.roomNum;
      }
      if (this.locationType) {
        query.location = this.locationType;
      }
      if (!this.roomNum && !this.locationType) {
        this.$router.push({ path: "/myOrder" });
      } else {
        this.$router.push({
          path: "/myOrder",
          query: query,
        });
      }
    },
    async chooseType(mealPeriod, needShowId) {
      var that = this;
      that.addFoodBtn = true;
      that.tips = "";
      that.curMobilePrompt = mealPeriod.mobilePrompt || "";
      that.maxMenuItem = Number(mealPeriod.advancedConfig.maxMenuItem || 0); // 最大购买数量
      let now = moment(new Date()).tz(localStorage.getItem("timeZone"));

      let param = {
        hotelId: localStorage.getItem("hotelId"),
        mealPeriodId: mealPeriod.id,
      };
      this.isAllowAdvanced = mealPeriod.isAllow;
      localStorage.setItem("autoClosePaid", mealPeriod.autoClosePaid);

      getAction(that.url.getTipsByMealPeriodId, param).then((res) => {
        that.tips = res.data.tips;
        that.timeOpen = res.data.timeOpen;
        let mealPeriodOpenTimeArr = [];
        //兼容safari浏览器
        res.data.mealPeriodOpenTimeList.map((item) => {
          let obj = {
            date: item.date.replace(/-/g, "/"),
            fromTime: item.fromTime,
            toTime: item.toTime,
            is24Available: item.is24Available,
            isCrossDay: item.isCrossDay,
          };
          mealPeriodOpenTimeArr.push(obj);
        });
        that.nowMealPeriodAdvanced = mealPeriod.isAllow;

        // 添加按钮是否禁用,可预约的菜单针对特殊时间可预约进行处理

        if (mealPeriod.isAllow) {
          if (
            mealPeriod.advancedStartDate != "Tomorrow" &&
            mealPeriod.advancedStartDate != "Today"
          ) {
            this.addFoodBtn = res.data.timeOpen;
          } else {
            this.addFoodBtn = res.data.timeOpen;
          }
        } else {
          this.addFoodBtn = res.data.timeOpen;
        }

        if (mealPeriodOpenTimeArr.length == 0) {
          this.addFoodBtn = false;
        }

        //不可预约点餐，如果离结束还剩不到orderingEndTime分钟，禁止点餐
        if (!mealPeriod.isAllow && mealPeriodOpenTimeArr.length > 0) {
          this.endTimeTody = moment
            .tz(
              moment(
                mealPeriodOpenTimeArr[0].date +
                  " " +
                  mealPeriodOpenTimeArr[0].toTime
              ).format("YYYY-MM-DD HH:mm"),
              localStorage.getItem("timeZone")
            )
            .valueOf();

          if (
            now.valueOf() + 1000 * 60 * res.data.orderingEndTime >=
            this.endTimeTody
          ) {
            this.nearEndTipTime = mealPeriodOpenTimeArr[0].toTime;
            this.nearEndTipTime = moment(
              this.endTimeTody - 1000 * 60 * res.data.orderingEndTime
            )
              .tz(localStorage.getItem("timeZone"))
              .format("hh:mm A");
            this.addFoodBtn = false;
          }
        }

        //将开放时间和提示放到vuex
        let mealPeriodOpenTime = {
          mealPeriod: mealPeriod.mealPeriod,
          mealPeriodId: mealPeriod.id,
          mealPeriodOpenTimeArr: mealPeriodOpenTimeArr,
          tips: that.tips,
          isAllowAdvanced: mealPeriod.isAllow,
          advancedConfig: mealPeriod.advancedConfig,
          notAdvancedConfigList: mealPeriod.notAdvancedConfigList,
          enableText: mealPeriod.enableText,
          discount: mealPeriod.discount,
          timeOpen: that.timeOpen,
        };
        that.updateMealPeriodOpenTime(mealPeriodOpenTime);

        if (mealPeriod.id != that.active) {
          that.active = mealPeriod.id;
          this.enableUpc = mealPeriod.enableUpc;
          that.activeMealPeriod = mealPeriod.mealPeriod;
          for (let i = 0; i < that.mealPeriodList.length; i++) {
            const element = that.mealPeriodList[i];
            if (element.id == mealPeriod.id) {
              that.categoryList = element.categoryList;
              if (that.categoryList.length > 0) {
                if (needShowId != null) {
                  that.activeName = needShowId;
                } else {
                  that.activeName = that.categoryList[0].id;
                }
              }
            }
          }
        } else {
          if (needShowId != null) {
            that.activeName = needShowId;
          }
        }
        if (needShowId != null) {
          setTimeout(() => {
            const targetElement = document.getElementById(
              "collapse" + that.activeName
            );
            targetElement.scrollIntoView({ behavior: "smooth" });
          }, 400);
        } else {
          that.$nextTick(() => {
            setTimeout(() => {
              that.$refs.categoryContent.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }, 100);
          });
        }
        this.loading = false;
      });
    },
    initData() {
      // this.showDisclaims = false
      this.mealPeriodList = [];
      var param = {
        hotelId: localStorage.getItem("hotelId"),
      };

      param.locationType = this.locationType;

      getAction(this.url.list, param).then(async (res) => {
        if (res.msg == "SUCCESS") {
          this.mealPeriodList = res.data;
          //数量初始化
          for (let i = 0; i < this.mealPeriodList.length; i++) {
            const mealPeriod = this.mealPeriodList[i];
            let mealPeriodAdvanced = {
              advancedConfig: mealPeriod.advancedConfig,
              isAllow: mealPeriod.isAllow,
              id: mealPeriod.id,
            };
            this.mealPeriodAdvancedArr.push(mealPeriodAdvanced);
            for (let j = 0; j < mealPeriod.categoryList.length; j++) {
              const category = mealPeriod.categoryList[j];
              if (category.upsell === true) {
                this.upsellCategorysId.push(category.id);
                this.upsellCategorys.push(category);
              }
              for (let k = 0; k < category.mainDishList.length; k++) {
                const food = category.mainDishList[k];
                food.num = 0;
                for (let l = 0; l < this.shoppingFood.length; l++) {
                  const shoppFood = this.shoppingFood[l];
                  if (food.id == shoppFood.foodId) {
                    food.num += shoppFood.num;
                  }
                }
                //数据初始化
                food.foods = [];
                food.drinks = [];
                food.drinksChildren = [];
                food.drinksChildrenChecked = "";
                food.remark = "";
                // food.isFull = true;
                //区分饮品和其他
                for (let i = 0; i < food.sideDishList.length; i++) {
                  const element = food.sideDishList[i];
                  if (element.isBeverage) {
                    food.drinks.push(element);
                  } else {
                    food.foods.push(element);
                  }
                }
                //=============赋予默认值start
                for (let i = 0; i < food.foods.length; i++) {
                  const element = food.foods[i];
                  if (element.optionList.length > 0) {
                    if (element.type == "Radio") {
                      //   element.checked = ''
                      element.checked = [element.optionList[0].id];
                    } else {
                      element.checked = [];
                      // element.checked = [element.optionList[0].id];
                    }
                  }
                }
                //=============赋予默认值end
                //饮品处理
                for (let i = 0; i < food.drinks.length; i++) {
                  const element = food.drinks[i];
                  if (i == 0) {
                    element.checked = true;
                  } else {
                    element.checked = false;
                  }
                }
                if (food.drinks.length > 0) {
                  food.drinksChildren = food.drinks[0].optionList;
                  //=============赋予默认值start
                  if (food.drinksChildren.length > 0) {
                    // food.drinksChildrenChecked = ''
                    food.drinksChildrenChecked = food.drinksChildren[0].id;
                  }
                  //=============赋予默认值end
                }
              }
            }
          }
          localStorage.setItem(
            "mealPeriodAdvanced",
            JSON.stringify(this.mealPeriodAdvancedArr)
          );

          // 根据时间选择当前Meal
          let isSelectActive = false;
          let dateList = []; // 记录每个类目的营业时间  chooseType的时候防止二次请求

          for (let mealPeriod of this.mealPeriodList) {
            let isActive = await getAction(this.url.getTipsByMealPeriodId, {
              hotelId: localStorage.getItem("hotelId"),
              mealPeriodId: mealPeriod.id,
            }).then((res) => {
              if (res.msg === "SUCCESS") {
                return res.data.timeOpen;
              } else {
                return false;
              }
            });
            if (isActive) {
              this.chooseType(mealPeriod, null);
              isSelectActive = true;
              localStorage.setItem("autoClosePaid", mealPeriod.autoClosePaid);
              break;
            }
          }
          if (!isSelectActive) {
            if (this.mealPeriodList.length > 0) {
              this.chooseType(this.mealPeriodList[0], null);
              localStorage.setItem(
                "autoClosePaid",
                this.mealPeriodList[0].autoClosePaid
              );
            } else {
              this.loading = false;
            }
          }
        }
      });
    },
    choseDrink(val) {
      var target = null;
      for (let i = 0; i < this.selectFood.drinks.length; i++) {
        const item = this.selectFood.drinks[i];
        item.checked = false;
        if (item.id == val) {
          target = item;
        }
      }
      if (target) {
        target.checked = true;
        this.selectFood.drinksChildren = target.optionList;
        // if (this.selectFood.drinksChildren.length > 0) {
        // this.selectFood.drinksChildrenChecked = ''
        // this.selectFood.drinksChildrenChecked =
        //   this.selectFood.drinksChildren[0].id
        // }
      }
    },
    showFoodInfo(food, categoryId) {
      // 停车计费 判断点击的商品是不是已经在购物车了 是就编辑原来的数据
      food.categoryId = categoryId;
      if (
        this.isParking() &&
        this.shoppingFood.findIndex((item) => item.foodId === food.id) > -1
      ) {
        this.selectFood = food;
        let item = this.shoppingFood.find((item) => item.foodId === food.id);
        const { fromDate, fromTime, toDate, toTime } = item;
        this.parkingPopupVisible = true;
        this.$nextTick(() => {
          this.$refs.parkingPopupRef.init(this.selectFood, {
            fromDate,
            fromTime,
            toDate,
            toTime,
          });
        });
        return;
      }
      // 最大下单商品数量提示
      if (
        this.maxMenuItem !== 0 &&
        this.shoppingFood.length >= this.maxMenuItem
      ) {
        this.$refs.maxMenuTipRef.init(this.maxMenuItem);
        return;
      }
      if (food.oosStatus) {
        return;
      }
      // 不允许点餐
      this.selectFood = lodash.cloneDeep(food);

      // 停车费用计算 天数
      if (this.isParking()) {
        this.parkingPopupVisible = true;
        this.$nextTick(() => {
          this.$refs.parkingPopupRef.init(this.selectFood);
        });
        return;
      }

      this.selectFood.num = 1;
      this.foodInfoShow = true;
    },
    ScanCodeConfirmFood(food) {
      this.selectFood = lodash.cloneDeep(food);
      this.selectFood.num = food.num;
      this.activeName = food.categoryId;
      setTimeout(() => {
        const targetElement = document.getElementById(
          "collapseMainItem" + food.id
        );
        targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 400);
      this.addShoppingFood(false);
    },
    radioChange(type, index) {
      if (type == "radio") {
        if (this.selectFood.foods[index].checked.length > 1) {
          this.selectFood.foods[index].checked.shift();
        }
      } else if (type == "beverage") {
        if (this.selectFood.drinksChildrenChecked.length > 1) {
          this.selectFood.drinksChildrenChecked.shift();
        }
      }
    },
    parkingCallback({ num, dataForm }) {
      this.selectFood = Object.assign({}, this.selectFood, {
        num,
        ...dataForm,
      });
      this.addShoppingFood(dataForm.isEdit ? true : false);
      this.placeOrder();
    },
    addShoppingFood(parkingIsEdit = false) {
      if (this.shoppingFood.length > 0) {
        if (this.active != this.shoppingFood[0].mealPeriodId) {
          let target = this.mealPeriodList.find((item) => {
            return item.id == this.shoppingFood[0].mealPeriodId;
          });
          //如果target为空，可能是客人访问了某个rs link并加入购物车，然后又访问了新的link
          //则提示 'please remove the order not in this menu firstly.'
          if (!target) {
            Toast("There are items not in menu." + "please remove firstly.");
          } else {
            Toast(
              "Please finish or remove the " +
                target.mealPeriod +
                " order firstly."
            );
          }
          return;
        }
      }

      // 添加disclaimer
      let disclaimerList = [];
      let category = this.categoryList.find(
        (item) => item.id === this.selectFood.categoryId
      );

      category.disclaimerList.forEach((item) => {
        if (item.enable) {
          disclaimerList.push(item);
        }
      });
      console.log(category);

      let revenueCenter = this.mealPeriodList.find(
        (mealPeriod) => mealPeriod.id === this.active
      ).revenueCenter;

      if (this.selectFood.num > 0) {
        var food = {
          id: randomUUID(), //订单餐品id
          foodId: this.selectFood.id, //餐品id
          drinks: this.selectFood.drinks, //所有饮品类别及其下饮品
          drinksChildren: this.selectFood.drinksChildren, //饮品类别下的饮品
          drinksChildrenChecked: this.selectFood.drinksChildrenChecked, //饮品类别下的选择的饮品
          foods: this.selectFood.foods, //所有食物
          remark: this.selectFood.remark,
          hotelId: this.selectFood.hotelId,
          name: this.selectFood.name,
          // isFull: this.selectFood.isFull,
          fullPrice: this.selectFood.fullPrice,
          // halfPrice: this.selectFood.halfPrice,
          // fullName: this.selectFood.fullName,
          // halfName: this.selectFood.halfName,
          sideDishList: this.selectFood.sideDishList,
          allPrice: 0,
          num: this.selectFood.num,
          // mealPeriodId: this.selectFood.mealPeriodId,
          mealPeriodId: this.active,
          revenueCenter,
          // categoryId: this.selectFood.categoryId,
          categoryId: this.selectFood.categoryId,
          disclaimer: disclaimerList || null,
          omnId: this.selectFood.omnId,
          messageModifierId: this.selectFood.messageModifierId,
          accountingType: this.selectFood.accountingType
            ? this.selectFood.accountingType
            : category.accountingType,
        };
        // 停车Parking 参数
        if (this.isParking()) {
          const {
            fromDate,
            fromTime,
            toDate,
            toTime,
            chargeType,
            chargeUnit,
            midNight,
            mobileChargeName,
          } = this.selectFood;
          food = Object.assign({}, food, {
            fromDate,
            fromTime,
            toDate,
            toTime,
            chargeType,
            chargeUnit,
            midNight,
            mobileChargeName,
          });
        }
        //计算价格
        for (let i = 0; i < food.drinksChildren.length; i++) {
          const drink = food.drinksChildren[i];
          if (drink.id == food.drinksChildrenChecked) {
            food.allPrice += Number(drink.price);
          }
        }
        for (let i = 0; i < food.foods.length; i++) {
          const element = food.foods[i];
          for (let j = 0; j < element.optionList.length; j++) {
            const item = element.optionList[j];
            if (element.checked.indexOf(item.id) != -1) {
              food.allPrice += Number(item.price);
            }
            // if (element.type == "Radio") {
            //   if (item.id == element.checked) {
            //     food.allPrice += Number(item.price);
            //   }
            // } else {
            //   if (element.checked.indexOf(item.id) != -1) {
            //     food.allPrice += Number(item.price);
            //   }
            // }
          }
        }
        food.allPrice += Number(food.fullPrice);
        food.allPrice = food.allPrice * food.num;

        // 停车费编辑 Next 去重
        if (parkingIsEdit) {
          this.clearFood();
        }
        this.addFood(food);
        // this.selectFood.num++
        for (let i = 0; i < this.categoryList.length; i++) {
          const category = this.categoryList[i];
          for (let j = 0; j < category.mainDishList.length; j++) {
            const food = category.mainDishList[j];
            if (food.id == this.selectFood.id) {
              this.$set(this.categoryList[i].mainDishList, j, {
                ...food,
                num: food.num + this.selectFood.num,
              });
            }
          }
        }
        this.foodInfoShow = false;
      } else {
        this.foodInfoShow = false;
      }
    },
    close() {
      this.foodInfoShow = false;
    },
    placeOrder() {
      if (this.shoppingFood.length == 0) {
        return;
      }
      let selectCategoryIds = Array.from(
        new Set(this.shoppingFood.map((item) => item.categoryId))
      );
      let isUpsell = selectCategoryIds.some((id) => {
        return this.upsellCategorysId.find((item) => id === item);
      });
      this.upsellCategorys = this.upsellCategorys.filter((item) => {
        // return this.shoppingFood.findIndex(food => item.mealPeriodId == food.mealPeriodId) != -1 && selectCategoryIds.findIndex(id => item.category == id) != 1
        return (
          selectCategoryIds.findIndex((id) => item.id == id) === -1 &&
          this.shoppingFood.findIndex(
            (food) => item.mealPeriodId == food.mealPeriodId
          ) != -1
        );
      });
      if (this.upsellCategorys.length != 0) {
        // 筛选upsell categorys
        this.upsellShow = true;
      } else {
        let query = {};
        if (this.roomNum) {
          query.room = this.roomNum;
        }
        if (this.locationType) {
          query.location = this.locationType;
        }
        if (!this.roomNum && !this.locationType) {
          this.$router.push({ path: "/placeOrder" });
        } else {
          this.$router.push({
            path: "/placeOrder",
            query: query,
          });
        }
      }
    },
    skipUpsell() {
      this.upsellShow = false;
      let query = {};
      if (this.roomNum) {
        query.room = this.roomNum;
      }
      if (this.locationType) {
        query.location = this.locationType;
      }
      if (!this.roomNum && !this.locationType) {
        this.$router.push({ path: "/placeOrder" });
      } else {
        this.$router.push({
          path: "/placeOrder",
          query: query,
        });
      }
    },
    okUpsell() {
      this.upsellShow = false;
      let query = {};
      if (this.roomNum) {
        query.room = this.roomNum;
      }
      if (this.locationType) {
        query.location = this.locationType;
      }
      if (!this.roomNum && !this.locationType) {
        this.$router.push({ path: "/placeOrder" });
      } else {
        this.$router.push({
          path: "/placeOrder",
          query: query,
        });
      }
    },
    checkbox(item, id) {
      if (item.type == "Radio") {
        if (item.checked.length == 0) {
          Toast("Choose at least 1");
          item.checked.push(id);
        } else if (item.maxNum == 1) {
          item.checked = [id];
        }
      }
    },
    checkedChange(item) {
      if (item.type == "Radio" && item.maxNum == 1) {
        return;
      }
      if (item.checked.length > item.maxNum) {
        Toast("Choose up to " + item.maxNum);
        item.checked.pop();
        return;
      }
    },
    showInfo() {
      Dialog.alert({
        message: this.disclaimer,
        confirmButtonText: "Confirm",
        theme: "round-button",
        confirmButtonColor: this.primaryColor,
      }).then(() => {
        // on close
      });
    },
    showIconInfo() {
      this.showIcon = true;
    },
    isParking() {
      if (
        this.locationType != null &&
        this.locationType.indexOf("Parking") > -1
      ) {
        return true;
      }
      return false;
    },
    getHotelInfo() {
      getAction(this.url.getHotelInfo, {
        hotelId: localStorage.getItem("hotelId"),
      }).then((res) => {
        if (this.$route.query.use_sign_color) {
          this.editColor("#" + this.$route.query.use_sign_color);
        } else {
          this.editColor("#" + res.data.color);
        }

        this.brandUrl =
          // "https://www.hotelvoicebot.com/download/resource/logo/" +
          "https://hotelfile353485474178.s3-accelerate.amazonaws.com/logo/" +
          res.data.brand.toLowerCase().replace(" ", "_") +
          ".png";
      });
    },
    showUpsell(id) {
      let mealPeriod = this.mealPeriodList.find((item) => {
        return (
          item.id == this.active &&
          item.categoryList.findIndex((category) => category.id === id) != -1
        );
      });
      this.chooseType(mealPeriod, id);
      this.upsellShow = false;
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/.van-nav-bar__right {
  position: static !important;
}

/deep/.van-nav-bar__right:active {
  opacity: 1;
}

/deep/.navbar-scan {
  fill: #fff !important;
  font-size: 20pt;
}

.home {
  width: 100%;

  .toast {
    word-break: normal;
  }

  .word-break {
    word-break: normal;
  }

  height: 100%;
  background: #f5f4f4;
  display: flex;
  flex-flow: column;
  align-items: center;

  /deep/ .van-nav-bar {
    background-color: $primary-color;
    height: 35pt;
    width: 100%;

    .van-nav-bar__title {
      color: #fff;
      font-size: 14pt;
      margin: 0 0 0 5pt;
      max-width: 85%;
    }
  }

  /deep/ .van-grid-item__content {
    background: #f5f4f4;
    padding: 17pt 3.75pt 7.5pt;
  }

  /deep/ .van-nav-bar__content {
    height: 100%;
  }

  .section-area {
    position: relative;
    width: calc(100% - 40pt);
    margin: 1pt 20pt;

    .section {
      display: flex;
      align-items: center;
      overflow-x: auto;
      width: 100%;
      height: 40pt;

      .item {
        flex-shrink: 0;
        // width: 31%;
        display: flex;
        align-content: center;
        justify-content: center;
        margin-right: 20px;

        .top-text {
          align-self: center;
          margin-top: 5pt;
          font-size: 10pt;
          font-weight: bold;
          padding: 4pt;
          border-radius: 3pt;
          width: fit-content;
          white-space: nowrap;
        }

        .activeType {
          align-self: center;
          color: #fff;
          background: $primary-color !important;
        }
      }

      .item:last-child {
        margin: 0;
      }
    }

    // .section::-webkit-scrollbar {
    //   display: none;
    // }

    .arrow-left {
      position: absolute;
      left: -6%;
      top: 33%;
    }

    .arrow-right {
      position: absolute;
      right: -6%;
      top: 33%;
    }
  }

  .content {
    height: calc(100% - 145pt);
    width: calc(100% - 12pt);
    padding: 0 10pt;
    text-align: left;
    overflow: auto;

    .van-collapse-item--border::after {
      border-top: none;
    }

    .van-collapse-item {
      margin-bottom: 10pt;
    }

    .food-collapse {
      .item {
        padding: 10pt 0 2pt;
        border-bottom: 1pt dashed #e8e8e8;
      }
    }

    /deep/ .van-cell {
      padding: 10pt 16pt;

      &::after {
        bottom: 2px;
        border-bottom: 4px solid rgb(223, 223, 223);
      }
    }

    /deep/ .van-cell__title,
    .van-cell__value {
      font-size: 13pt;
      font-family: ArialRoundedMTBold;
      color: #2b2e36;
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: normal;
    }

    /deep/ .van-collapse-item__content {
      padding-top: 0;
      padding-bottom: 0;
    }

    /deep/.food-collapse-right-slot {
      position: absolute;
      right: 12px;
      top: 16pt;
      width: 5px;
      height: 5px;
      background-color: red;
      border-radius: 50%;
      flex-shrink: 0;
    }
    /deep/.van-cell__right-icon {
      right: 6px;
    }
  }

  .bottom {
    height: 30pt;
    width: calc(100% - 20pt);
    background: #ffffff;
    border-radius: 25pt;
    margin: 5pt 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    /deep/ .van-badge--fixed {
      top: 10pt;
    }

    img {
      width: 20pt;
      height: 20pt;
      background-repeat: no-repeat;
      margin-left: 20pt;
    }

    .price {
      font-size: 13pt;
      font-family: Arial-Black, Arial;
      font-weight: 900;
      color: #2e2e2e;
    }

    .btn {
      width: 120pt;
      height: 30pt;
      background: #2b2e36;
      background: $primary-color;
      border-radius: 25pt;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-size: 13pt;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
      }
    }
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5pt 10pt;

    .footer-item {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30pt;
      background-color: $primary-color;
      border-radius: 25pt;
      width: 120pt;

      /deep/ .van-badge--fixed {
        top: 10pt;
      }

      /deep/.footer-scan {
        fill: #fff;
        width: 18pt;
      }

      span {
        font-size: 11pt;
        font-weight: 600;
        color: #ffffff;
        font-family: PingFangSC-Semibold, PingFang SC;
        margin-left: 4px;
      }
    }

    .footer-item:nth-child(2) {
      margin-left: 10pt;
    }
  }

  .footer-staff {
    height: 30pt;
    width: calc(100% - 20pt);
    background: $primary-color;
    border-radius: 5pt;
    margin: 5pt 0;

    font-size: 14pt;
    font-weight: 600;
    color: #ffffff;
    font-family: PingFangSC-Semibold, PingFang SC;
    text-align: center;
    line-height: 30pt;
  }

  .foodModel {
    text-align: left;
    background: #f9f9f9;

    .title {
      background-color: #ffffff;
      padding: 0 10pt;
      border-bottom: 1pt #dcdcdc solid;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 60pt;

      span {
        font-size: 14pt;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #2b2e36;
      }
    }

    .item {
      background-color: #ffffff;
      padding: 0pt 10pt 5pt;
      margin-bottom: 2pt;

      .item-name {
        span {
          font-size: 12pt;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #272727;
        }
      }

      .checked-group {
        /deep/ .van-checkbox__label {
          font-size: 12pt;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #2b2e36;
          line-height: 20pt;
          margin-left: 10pt;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding-right: 30pt;
        }

        /deep/ .van-checkbox__icon--checked .van-icon {
          //background-color: #686868;
          //border-color: #686868;
          background-color: $primary-color;
          border-color: $primary-color;
        }
      }

      .radio-group {
        /deep/ .van-radio__label {
          font-size: 12pt;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #2b2e36;
          line-height: 20pt;
          margin-left: 10pt;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding-right: 30pt;
        }

        /deep/ .van-radio__icon--checked .van-icon {
          //background-color: #686868;
          //border-color: #686868;
          background-color: $primary-color;
          border-color: $primary-color;
        }
      }

      .select {
        display: flex;
        align-items: center;
        margin-bottom: 5pt;

        .type {
          width: 60pt;
          height: 20pt;
          background: #ffffff;
          border-radius: 3.5pt;
          border: 1pt solid #979797;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10pt;

          span {
            font-size: 12pt;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #2b2e36;
          }
        }

        .type-checked {
          // background: #f7f7f7;
          //background: rgba(0, 0, 0, 0.7);
          background-color: $primary-color;
          border: 1pt solid $primary-color;
          width: 60pt;
          height: 20pt;
          border-radius: 3.5pt;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10pt;

          span {
            font-size: 12pt;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            // color: #2b2e36;
            color: #ffffff;
          }
        }
      }
    }

    .remark {
      background-color: #ffffff;
      padding: 0pt 10pt 5pt;

      .item-name {
        span {
          font-size: 12pt;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #272727;
        }
      }

      .input {
        margin-top: 5pt;
        margin-bottom: 10pt;
        border-radius: 1pt;
        border: 1pt solid #979797;
      }
    }

    .btn {
      height: 30pt;
      border-radius: 5pt;
      background-color: $primary-color;

      /deep/ .van-button__text {
        font-size: 12pt;
      }
    }
  }

  .iconModel {
    text-align: left;
    background: #ffffff;
    width: 80%;

    .title {
      background-color: #ffffff;
      padding: 0 10pt;
      border-bottom: 1pt #dcdcdc solid;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 30pt;

      span {
        font-size: 14pt;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #2b2e36;
      }
    }

    .icon {
      width: 30px;
      height: 30px;
    }
  }
}

.HOUIT-home {
  .content {
    height: calc(100% - 110pt - 88px);
  }
}

/deep/ .van-toast {
  font-size: 15px;
  word-break: normal;
  text-align: left !important;
}

/deep/ .van-cell__label {
  font-weight: normal;
}

.upsellModel {
  padding: 10pt;
  width: 260pt;
  background: #f9f9f9;
  font-size: 12pt;

  .title {
    font-weight: 600;
    font-size: 12pt;
    margin-bottom: 10pt;
  }

  /deep/.van-icon {
    color: $primary-color;
  }

  .content {
    .item {
      line-height: 16pt;
      padding: 4pt 0;
      font-size: 11pt;

      span {
        text-decoration: underline;
      }
    }
  }

  .btn-group {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: 10pt;
    margin-top: 26pt;

    .btn-skip {
      border-color: $primary-color;
      color: $primary-color;
      width: 100%;
    }
  }
}

.hotelname {
  color: #fff;
  font-size: 10pt;
  margin-left: 10px;
  text-align: left;
  //width: 200pt;
  width: calc(100vw - 110pt);
  word-break: normal;
  line-height: 11pt;
  font-weight: normal;
  font-family: Avenir, Helvetica, Arial, sans-serif;
}

.orderNum {
  color: #fff;
  border: 1px solid #fff;
  padding: 0 3px;
  background-color: #ee0a24;
  min-width: 16px;
  position: absolute;
  right: 10pt;
  top: 5pt;
  font-size: 12px;
  line-height: 1.2;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
